@import 'bootstrap/dist/css/bootstrap.min.css';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* Light theme by default */
:root,
[data-theme='light'] {
  --bs-body-bg: #ffffff;
  --bs-body-color: #000000;
  --bs-link-color: #007bff;
  --bs-navbar-bg: #f8f9fa;
  --bs-footer-bg: #f8f9fa;
}

/* Dark theme overrides */
[data-theme='dark'] {
  --bs-body-bg: #212529;
  --bs-body-color: #ffffff;
  --bs-link-color: #9aa0a6;
  --bs-navbar-bg: #343a40;
  --bs-footer-bg: #343a40;
}
